import { Spin, Row, Col, Button, Table, Drawer, notification } from 'antd';
import { useState, useEffect } from 'react';
import get from 'lodash/get';

import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import requireAuth from '../../hocs/requireAuth';
import cityColums from '../../constants/table/cityColumns';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';
import { createCity, getCityList,deleteCity } from '../../redux/actions/superAdminAction';
import { DELETED_CITY_SUCCESS } from "../../constants/messages";
import SelectCityAndCountry from './SelectCityAndCountry';


const CityScreen = () => {
  const [loading, setLoading] = useState(false);
  const [city, setCity] = useState(null);
  const [addCityObject, setAddCityObject] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [drawer, setDrawer] = useState({
    visible: false,
    edit: false,
    data: null,
  });

  const getCities = () => {
    setLoading(true);
    getCityList()
      .then((result) => {
        setLoading(false);
        console.log('City list is>>>', result);
        setCity(result);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const toggleAddCity = (visible, edit = false, data = null) => {
    setDrawer({
      visible,
      edit,
      data,
    });
    if(visible){
      setSelectedCountry(null);
      setSelectedCity(null);
      setAddCityObject(null);
    }
  };

  const addCity = (cityPayload) => {
    setLoading(true);
    createCity(cityPayload)
      .then((result) => {
        toggleAddCity(false);
        setLoading(false);
        notification.success({
          message: ('City added successfuly'),
        });
        getCities();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCities();
  }, []);

  const getGeoMetry = (details, geometry) => {
    const geo = geometry[0].geometry;

    setAddCityObject({
      name: get(details.value.terms[0], 'value'),
      country: get(
        details.value.terms[details.value.terms.length - 1],
        'value'
      ),
      lat: geo.location.lat(),
      lng: geo.location.lng(),
      ne_lat: geo.bounds.getNorthEast().lat(),
      sw_lat: geo.bounds.getSouthWest().lat(),
      ne_lng: geo.bounds.getNorthEast().lng(),
      sw_lng: geo.bounds.getSouthWest().lng(),
    });
    console.log('CITY PAYLOAD>>>>>', city);
  };
  const removeCity=(record)=>{
    deleteCity(record?.id)
      .then((result) => {
        console.log(result);
        notification.success({
          message: DELETED_CITY_SUCCESS,
        });
        getCities();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Spin spinning={loading}>
      <Row justify="space-between" style={{ alignItems: 'baseline' }}>
        <Col>
          <div className="screen-title">Cities</div>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => toggleAddCity(true)}
            icon={<PlusOutlined />}
          >
            Add City
          </Button>
        </Col>
      </Row>

      <Table columns={cityColums({removeCity})} dataSource={city} pagination={false} />
      <Drawer
        className="main-drawer"
        destroyOnClose
        width={350}
        closable={false}
        visible={get(drawer, 'visible')}
      >
        <>
          <div className="add-drawer-content">
            <Row justify="space-between">
              <Col className="drawer-title">Add City</Col>
              <Col>
                <CloseOutlined onClick={() => toggleAddCity(false)} />
              </Col>
            </Row>
            <div className="add-drawer-form">
              <SelectCityAndCountry selectedCountry={selectedCountry} selectedCity={selectedCity}
               setSelectedCountry={setSelectedCountry} setSelectedCity={setSelectedCity} setAddCityObject={setAddCityObject} />
              <br /> <br />
              <Button type="primary" onClick={() => addCity(addCityObject)}>
                Save
              </Button>
            </div>
          </div>
        </>
      </Drawer>
    </Spin>
  );
};
export default requireAuth(CityScreen, [1]);
