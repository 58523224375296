// import { useParams } from 'react-router-dom';
import { Button, Spin, Form, Input } from 'antd';
import { validateMessages } from '../../constants/validation';
import { TitleSelector } from '../../components/titleSelector/TitleSelector';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import './style.scss';
import { logout } from '../../redux/actions/authAction';
import { useParams } from 'react-router-dom';
import {
  registerStaff,
  validateStaffLink,
} from '../../redux/actions/staffAction';
import CustomResult from '../../components/customResult/CustomResult';
import assign from 'lodash/assign';
import {
  INVALID_LINK,
  INVALID_LINK_SUBTITLE,
  REGISTRATION_SUBTITLE_STAFF,
  SUCCESSFUL_REGISTRATION,
} from '../../constants/messages';
import SingleImageUpload from '../../components/mediaUpload/SingleImageUpload';
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import "react-phone-number-input/style.css";


const RegsiterStaffScreen = () => {
  // const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const { uuid } = useParams();
  const [showSuccess, setShowSuccess] = useState(false);
  const [registerDetails, setRegisterDetails] = useState({
    info: {},
    invite_uuid: '',
  });
  const [showForm, setShowForm] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    validateStaffLink(uuid)
      .then((result) => {
        setLoading(false);
        console.log(result);
        if (get(result, 'registerSuccess')) {
          setShowSuccess(true);
        } else {
          setRegisterDetails(result);
          setShowForm(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        setShowError(true);
      });
  }, [uuid]);

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = (values) => {
    values = {...values,image:imageUser?.key,thumbnail:thumbnail?.thumbnail_key,
      contact_number: contactNumber,
      contact_code: contactCode,}
    if(imageUser?.key !='undefined' && imageUser?.key != null && imageUser?.key != undefined){
      values = {...values,image:imageUser?.key,thumbnail:thumbnail?.thumbnail_key}
    }else{
      values = {...values,image:""}
    }
    assign(values, { invite_link_uuid: uuid });
    setLoading(true);
    registerStaff(values)
      .then((result) => {
        setLoading(false);
        setShowSuccess(true);
        setShowForm(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const dispatch = useDispatch();

  dispatch(logout())
    .then(() => {})
    .catch((error) => {});

  console.log('Show form', showForm);
  console.log('Result', registerDetails);
  const [imageUser, setUserImage] = useState();
  const [thumbnail,setThumbnail]= useState();
  const onUploadLogoDone = (imageResponse) => {
    setUserImage(imageResponse);
    setThumbnail(imageResponse);
  };
  const [contactCode, setContactCode] = useState(get(registerDetails, "contact_code"));
  const [contactNumber, setContactNumber] = useState(
    get(registerDetails, "contact_number")
  );

  const onChangeFormData = (value, key) => {
    if (key === "contact_number") {
      const phoneNumber = parsePhoneNumberFromString(String(value));
      if (phoneNumber && phoneNumber?.isValid()) {
        const formattedNumber = phoneNumber?.nationalNumber;
        const callingCode = phoneNumber?.countryCallingCode;
        setContactCode(callingCode);
        setContactNumber(formattedNumber);
      }
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="register-staff-screen">
        {showForm && (
          <div className="staff-form">
            <Form
              name="control-hooks"
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              validateMessages={validateMessages}
              initialValues={{
                email: get(registerDetails, 'email'),
                title: 'Ms',
                name: get(registerDetails, 'name'),
                // contact_code: '91',
                contact_number: "+" + contactCode + contactNumber,
              }}
            >
              <Form.Item className="doctor-image" 
          rules={[
            {
              required: true,
              message: 'Please upload a doctor image.',
            },
          ]}
          >
            <SingleImageUpload
              onUploadDone={onUploadLogoDone}
              imageUrl={get(thumbnail, 'url')}
              setUserImage={setThumbnail}
            />
          </Form.Item>
              <Form.Item
                label="Staff's Name"
                name="name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input className="custom-input" addonBefore={TitleSelector} />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: 'email',
                  },
                ]}
              >
                <Input className="custom-input" disabled={true} />
              </Form.Item>
              <Form.Item
                label="Create Password"
                name="password"
                rules={[
                  {
                    required: true,
                  },
                  {
                    min: 6,
                    message: 'Password must be atleast 6 characters',
                  },
                ]}
              >
                <Input.Password className="custom-input" />
              </Form.Item>
              <Form.Item
                label=" Confirm Password"
                name="confirm_password"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          'The two passwords that you entered do not match!'
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password className="custom-input" />
              </Form.Item>
              <Form.Item
            label="Contact Number"
            name="contact_number"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <PhoneInput
                  size="small"
                  fontSize={{ fontSize: "14px !important" }}
                  placeholder="Phone"
                  onChange={(value) =>
                    onChangeFormData(value, "contact_number")
                  }
                />
          </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Register
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
        {showSuccess && (
          <CustomResult
            status="success"
            title={SUCCESSFUL_REGISTRATION}
            subTitle={REGISTRATION_SUBTITLE_STAFF}
            fromstaff="Staff"
          />
        )}
        {showError && (
          <CustomResult
            status="error"
            title={INVALID_LINK}
            subTitle={INVALID_LINK_SUBTITLE}
          />
        )}
      </div>
    </Spin>
  );
};
export default RegsiterStaffScreen;
