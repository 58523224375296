import React, { useEffect, useState } from "react";
import {
  Select,
} from "antd";
import {
  getPatientMembers,
} from "../../redux/actions/doctorAction";
import SelectionTypeForm from "./SelectionTypeForm";
const { Option } = Select;

const EmergencyFormScreen = ({
  doctor_uuid,
  hospital_uuid,
  clickSubmit,
  closeModal,
  currentSlot,
  slotType,
  getAppointments,
  filter,
  selectedFilteredUser,
  setSelectedFilteredUser,
  consultationType,
  setConsultationType,
  setAppointmentBooked
}) => {
  const [membersData, setMembersData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getPatientMembers({hospital_uuid:hospital_uuid})
      .then((result) => {
        setMembersData(result);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("error", err);
      });
  }, []);

  return (
    <div style={{marginTop:"24px"}}>
      <SelectionTypeForm
        selectedFilteredUser={selectedFilteredUser}
        setSelectedFilteredUser = {setSelectedFilteredUser}
        consultationType = {consultationType}
        setConsultationType = {setConsultationType}
        membersData={membersData}
        clickSubmit={clickSubmit}
        closeModal={closeModal}
        currentSlot={currentSlot}
        slotType={slotType}
        doctor_uuid={doctor_uuid}
        hospital_uuid={hospital_uuid}
        getAppointments={getAppointments}
        filter={filter}
        setAppointmentBooked={setAppointmentBooked}
      />
    </div>
  );
};
export default EmergencyFormScreen;
