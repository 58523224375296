import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Country, City } from "country-state-city";

const SelectCityAndCountry = ({ selectedCountry, selectedCity,
    setSelectedCountry, setSelectedCity, setAddCityObject }) => {
  const [cities, setCities] = useState([]);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    const countryCities = City.getCitiesOfCountry(selectedOption.value);
    setCities(countryCities || []);
    setSelectedCity(null);
  };
const fetchBoundingBox = async (cityName, countryCode) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?city=${cityName}&country=${countryCode}&format=json`
      );
      const data = await response.json();
  
      if (data.length > 0) {
        return {
          ne_lat: parseFloat(data[0].boundingbox[1]), // Top latitude
          sw_lat: parseFloat(data[0].boundingbox[0]), // Bottom latitude
          ne_lng: parseFloat(data[0].boundingbox[3]), // Right longitude
          sw_lng: parseFloat(data[0].boundingbox[2]), // Left longitude
        };
      }
    } catch (error) {
      console.error("Error fetching bounding box:", error);
    }
    return null;
  };
  
  const handleCityChange = async (selectedOption) => {
    setSelectedCity(selectedOption);
    
    const cityData = City.getCitiesOfCountry(selectedCountry.value).find(city => city.name === selectedOption.label);
  
    if (cityData) {
      const boundingBox = await fetchBoundingBox(cityData.name, selectedCountry.label);
  
      setAddCityObject({
        name: cityData.name,
        country: selectedCountry.label,
        lat: cityData.latitude,
        lng: cityData.longitude,
        ne_lat: boundingBox?.ne_lat || null,
        sw_lat: boundingBox?.sw_lat || null,
        ne_lng: boundingBox?.ne_lng || null,
        sw_lng: boundingBox?.sw_lng || null,
      });
    }
  };
  

  return (
    <div>
      <h3>Select Country</h3>
      <Select
        options={Country.getAllCountries().map((country) => ({
          label: country.name,
          value: country.isoCode,
        }))}
        placeholder="Select a country..."
        value={selectedCountry}
        onChange={handleCountryChange}
        isSearchable
      />

      {selectedCountry && (
        <>
          <h3>Select City</h3>
          <Select
            options={cities.map((city) => ({
              label: city.name,
              value: city.name,
            }))}
            placeholder="Select a city..."
            value={selectedCity}
            onChange={handleCityChange}
            isSearchable
          />
        </>
      )}
    </div>
  );
};

export default SelectCityAndCountry;
