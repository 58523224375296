import { Button, Form, Input, notification, Select, Space, Spin } from 'antd';
// import './style.scss';

import { useEffect, useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import map from 'lodash/map';
import get from 'lodash/get';
import assign from 'lodash/assign';

import { validateMessages } from '../../../../constants/validation';
import {
  registerDoctor,
  specList,
  updateDoctor,
} from '../../../../redux/actions/doctorAction';
import './style.scss';
import { TitleSelector } from '../../../titleSelector/TitleSelector';
import { ContactCodeSelector } from '../../../contactCodeSelector/ContactCodeSelector';
import SingleImageUpload from '../../../mediaUpload/SingleImageUpload';
import { DETAILS_UPDATED } from '../../../../constants/messages';
import { switchHospital } from '../../../../redux/actions/authAction';
import { useDispatch, useSelector } from 'react-redux';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  geocodeByLatLng,
  getLatLng,
} from "react-google-places-autocomplete";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import "react-phone-number-input/style.css";

const { Option } = Select;

const EditDetails = ({
  setEditDetails,
  details,
  editForm,
  toggleResultSuccess,
  imageUser,
  setUserImage,
  setThumbnail,
  thumbnail
}) => {
  const [specialisations, setSpecialisation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    // hit api to get specialisation list
    specList().then((result) => {
      setSpecialisation(result);
    });
  }, []);

  const dispatch = useDispatch();

  const hospital_uuid = useSelector((state) =>
    get(state, 'auth.currentHospital.id')
  );

  const onFinish = (values) => {
    values = {...values,address_1: selectedAddress1,address_2 : selectedAddress2,state:state,country:country,image:imageUser?.key,thumbnail:thumbnail?.thumbnail_key,contact_number: contactNumber,
      contact_code: contactCode,};
    setLoading(true);
    if(imageUser?.key !='undefined' && imageUser?.key != null && imageUser?.key != undefined){
      values = {...values,image:imageUser?.key,thumbnail:thumbnail?.thumbnail_key}
    // assign(values, { image: get(imageUser, 'key') });
    }else{
      values = {...values,image:""}
    }
    if (editForm) {
      updateDoctor(values)
        .then((result) => {
          setLoading(false);
          setEditDetails(false);
          notification.success({
            message: (DETAILS_UPDATED),
          });

          dispatch(switchHospital({ hospital_uuid: hospital_uuid }))
            .then(() => {
              setLoading(false);
            })
            .catch((error) => {
              setLoading(false);
            });
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      assign(values, { user_exists: get(details, 'info.user_exists') });
      assign(values, { invite_link_uuid: get(details, 'invite_uuid') });
      registerDoctor(values)
        .then((result) => {
          setLoading(false);
          toggleResultSuccess();
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  //   {
  //   key: editForm ? get(details, 'image')?.replace(
  //     "https://res.cloudinary.com/dd2sdadrl/image/upload/v1669621246/",
  //     // `${process?.env?.CLOUDINARY_BUCKET_URL}`,
  //     ''
  //   ) : (
  //     get(details, 'info?.user?.image') ? get(details, 'info?.user?.image').replace(
  //       "https://res.cloudinary.com/dd2sdadrl/image/upload/v1669621246/",
  //       // `${process.env.CLOUDINARY_BUCKET_URL}`,
  //         ''
  //       ) : assign(details?.info?.user, {image : 
  //         "https://res.cloudinary.com/dd2sdadrl/image/upload/v1669621246/",
  //         // `${process?.env?.CLOUDINARY_BUCKET_URL}`
  //       })
  //   )    
  //   ,
  //   url: get(details, 'image'),
  // }
  

  const onUploadLogoDone = (imageResponse) => {
    setUserImage(imageResponse);
    setThumbnail(imageResponse);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const [placeId, setPlaceId] = useState();
  const [country,setCountry] = useState(details && details?.doctor_detail?.country);
  const [selectedLocation, setSelectedLocation] = useState(details && details?.doctor_detail?.address_1
    ? JSON.parse(details.doctor_detail?.address_1).selectedAddress
    : "");
  const [selectedLocation_2, setSelectedLocation_2] = useState(
      details && details?.doctor_detail?.address_2
        ? JSON.parse(details?.doctor_detail?.address_2).selectedAddress
        : ""
    );
  const [state,setState] = useState(details && details?.doctor_detail?.state);
  const onSuccessAddress_1 = (value,latLng) => {
    setSelectedAddress1(
      JSON.stringify({
        latLng: {lat:latLng?.lat,lng:latLng?.lng},
        placeId: value?.place_id,
        selectedAddress: value?.formatted_address
      })
    )
  };
  const onSuccessAddress_2 = (value,latLng) => {
    setSelectedAddress2(
      JSON.stringify({
        latLng: {lat:latLng?.lat,lng:latLng?.lng},
        placeId: value?.place_id,
        selectedAddress: value?.formatted_address
      })
    )
  };
  const [selectedAddress1, setSelectedAddress1] = useState(
    details?.doctor_detail?.address_1 ? details?.doctor_detail?.address_1 : ""
  );
  const [selectedAddress2, setSelectedAddress2] = useState(
    details?.doctor_detail?.address_2 ? details?.doctor_detail?.address_2 : ""
  );
  const getGeoMetry = (details, geometry) => {
    const geo = geometry[0].geometry;
    var addressData = {};
    const address_components = get(geometry[0], 'address_components');
    const formattedAddress = get(geometry[0], 'formatted_address').split(',');
    for (var i = 0; i < address_components.length; i++) {
      if (get(address_components[i], 'types').includes('country')) {
        assign(addressData, {
          address_country: get(address_components[i], 'long_name'),
        });
        setCountry(get(address_components[i], 'long_name'));
      }
      if (get(address_components[i], 'types').includes('postal_code')) {
        assign(addressData, {
          pin_code: get(address_components[i], 'long_name'),
        });
      }
      if (
        get(address_components[i], 'types').includes(
          'administrative_area_level_1'
        )
      ) {
        assign(addressData, {
          state: get(address_components[i], 'long_name'),
        });
        setState(get(address_components[i], 'long_name'));
      }
      if (get(address_components[i], 'types').includes('sublocality_level_1')) {
        assign(addressData, {
          suburb: get(address_components[i], 'long_name'),
        });
      }
    }
    assign(addressData, {
      address_1: formattedAddress.slice(-3).join('')
    });
    assign(addressData, {
      address_2: formattedAddress.slice(-3).join(''),
    });
    console.log('ADDRESS >>> ', addressData);
  };
  const [contactCode, setContactCode] = useState(get(details, "contact_code"));
  const [contactNumber, setContactNumber] = useState(
    get(details, "contact_number")
  );
  const onChangeFormData = (value, key) => {
    console.log("value", value, key);
    if (key === "contact_number") {
      const phoneNumber = parsePhoneNumberFromString(String(value));
      if (phoneNumber && phoneNumber?.isValid()) {
        const formattedNumber = phoneNumber?.nationalNumber;
        const callingCode = phoneNumber?.countryCallingCode;
        setContactCode(callingCode);
        setContactNumber(formattedNumber);
      }
    }
  };


  return (
    <Spin spinning={loading}>
      <div>
        <Form
          form={form}
          name="control-hooks"
          layout="vertical"
          initialValues={
            editForm
              ? {
                  name: get(details, 'name'),
                  specialisations: map(
                    get(details, 'specialisations'),
                    (s, i) => get(s, 'id')
                  ),
                  email: get(details, 'email'),
                  experience: get(details, 'doctor_detail.experience'),
                  provider_number: get(details, 'doctor_detail.provider_number'),
                  features: get(details, 'doctor_detail.features'),
                  about: get(details, 'doctor_detail.about'),
                  qualifications: get(details, 'doctor_detail.qualifications'),
                  title: get(details, 'title'),
                  // address_1: get(details, 'doctor_detail.address_1'),
                  // contact_code: get(details, 'contact_code'),
                  // contact_number: get(details, 'contact_number'),
                  contact_number: "+" + contactCode + contactNumber,
                  // address_2: get(details, 'doctor_detail.address_2'),
                  suburb: get(details, 'doctor_detail.suburb'),
                  state: get(details, 'doctor_detail.state'),
                  country: get(details, 'doctor_detail.country'),
                  pin_code: get(details, 'doctor_detail.pin_code'),
                }
              : {
                  name: get(details, 'info.user.name'),
                  title: 'Dr',
                  contact_code: '91',
                  email: get(details, 'info.email'),
                }
          }
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateMessages={validateMessages}
        >
          <Form.Item className="doctor-image" 
          rules={[
            {
              required: true,
              message: 'Please upload a doctor image.',
            },
          ]}
          >
            <SingleImageUpload
              onUploadDone={onUploadLogoDone}
              imageUrl={get(thumbnail, 'url')}
              setUserImage={setThumbnail}
            />
          </Form.Item>
          <Form.Item
            label=" Doctor's Name"
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input className="custom-input" addonBefore={TitleSelector} />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
              },
            ]}
          >
            <Input
              className="custom-input"
              disabled={editForm ? false : true}
            />
          </Form.Item>
          <Form.Item
            label="Contact Number"
            name="contact_number"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <PhoneInput
                  size="small"
                  fontSize={{ fontSize: "14px !important" }}
                  placeholder="Phone"
                  onChange={(value) =>
                    onChangeFormData(value, "contact_number")
                  }
                />
          </Form.Item>
          {!editForm && (
            <>
              {' '}
              <Form.Item
                label="Create Password"
                name="password"
                hidden={editForm}
                rules={[
                  {
                    required: true,
                  },
                  {
                    min: 6,
                    message: 'Password must be atleast 6 characters',
                  },
                ]}
              >
                <Input.Password className="custom-input" />
              </Form.Item>
              <Form.Item
                label=" Confirm Password"
                name="confirm_password"
                hidden={editForm}
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          'The two passwords that you entered do not match!'
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password className="custom-input" />
              </Form.Item>
            </>
          )}
          <Form.Item
            label=" Doctor's Provider Number"
            name="provider_number"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input className="custom-input" placeholder='Provider Number' />
          </Form.Item>
          <Form.Item
            label="Specialisation"
            name="specialisations"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select size="large" mode="multiple" style={{ fontSize: '14px' }}>
              {map(specialisations, (s, i) => (
                <Option key={i} value={get(s, 'id')}>
                  {get(s, 'name')}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Experience"
            name="experience"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input className="custom-input" suffix="years" />
          </Form.Item>

          <Form.Item
            label="Qualifications"
            name="qualifications"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input className="custom-input" />
          </Form.Item>
          <Form.Item label="Featured In" name="features">
            <Select size="large" mode="tags" style={{ fontSize: '14px' }}>
              {map(get(details, 'doctor_detail.features'), (d, i) => (
                <Option key={i} value={d}>
                  {d}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="About"
            name="about"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item
            label={
              <span>
            <span style={{ color: 'red' }}>*</span> Address 1
          </span>
            }
            name="address_1"
          >
            <GooglePlacesAutocomplete
              apiKey="AIzaSyCGT1QmwgNq90STSmzbKU3s9UQm_CofGfc"
              selectProps={{
                defaultInputValue: selectedLocation,
                onChange: async (va) => {
                  geocodeByPlaceId(
                    get(va, "value.place_id")
                  ).then((results) => {
                    getGeoMetry(va, results);
                    let filterData = results?.filter(
                      (result) =>
                        result.place_id == va.value.place_id
                    )[0];
                    setPlaceId(va?.value?.place_id);
                    getLatLng(filterData).then((latLng) => {
                    onSuccessAddress_1(filterData,latLng);
                    });
                  });
                },
              }}
              />
          </Form.Item>
          <Form.Item
            label="Address 2"
            name="address_2"
          >
            {/* <Input className="custom-input" /> */}
            <GooglePlacesAutocomplete
              apiKey="AIzaSyCGT1QmwgNq90STSmzbKU3s9UQm_CofGfc"
              selectProps={{
                defaultInputValue: selectedLocation_2,
                onChange: async (va) => {
                  geocodeByPlaceId(
                    get(va, "value.place_id")
                  ).then((results) => {
                    getGeoMetry(va, results);
                    let filterData = results?.filter(
                      (result) =>
                        result.place_id == va.value.place_id
                    )[0];
                    setPlaceId(va?.value?.place_id);
                    getLatLng(filterData).then((latLng) => {
                    onSuccessAddress_2(filterData,latLng);
                    });
                  });
                },
              }}
              />
          </Form.Item>

          <Form.Item
            label="Suburb"
            name="suburb"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input className="custom-input" />
          </Form.Item>
          {/* <Form.Item
            label="State"
            name="state"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input className="custom-input" disabled={true}/>
          </Form.Item>
          <Form.Item
            label="Country"
            name="country"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input className="custom-input" disabled={true}/>
          </Form.Item> */}
          <Form.Item
            label="Pincode"
            name="pin_code"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input className="custom-input" />
          </Form.Item>
          
          <Space size={40}>
            <Form.Item>
              <Button htmlType="submit" type="primary" loading={loading}>
                Save
              </Button>
            </Form.Item>
            <Form.Item></Form.Item>
          </Space>
        </Form>
      </div>
    </Spin>
  );
};

export default EditDetails;
