import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space, Form, Input, notification, Spin } from "antd";

import get from "lodash/get";

import { useState } from "react";
import { validateMessages } from "../../../constants/validation";
import { ContactCodeSelector } from "../../contactCodeSelector/ContactCodeSelector";
import { TitleSelector } from "../../titleSelector/TitleSelector";
import edit_white from "../../../assets/icons/edit_white.svg";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "antd/lib/avatar/avatar";
import SingleImageUpload from "../../mediaUpload/SingleImageUpload";
import { DETAILS_UPDATED } from "../../../constants/messages";
import "./style.scss";
import { updateUser } from "../../../redux/actions/staffAction";
import assign from "lodash/assign";
import { switchHospital } from "../../../redux/actions/authAction";
import * as TYPES from "../../../constants/actionTypes";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import "react-phone-number-input/style.css";

const UserDetails = ({ toggleUserProfileDrawer }) => {
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userImage, setUserImage] = useState();

  const details = useSelector((state) => get(state, "auth.user"));

  const toggleEdit = () => {
    setEdit(!edit);
    setUserImage(details?.image);
  };

  return (
    <Spin spinning={loading}>
      <div className="add-drawer-content">
        <Row justify="space-between">
          <Col className="drawer-title">Your Profile </Col>
          <Col>
            <CloseOutlined onClick={toggleUserProfileDrawer} />
          </Col>
        </Row>
        <div className="add-drawer-form">
          {edit ? (
            <EditUserDetails
              details={details}
              toggleEdit={toggleEdit}
              setLoading={setLoading}
              toggleUserProfileDrawer={toggleUserProfileDrawer}
              setUserImage={setUserImage}
              userImage={userImage}
            />
          ) : (
            <ViewUserDetails
              details={details}
              toggleEdit={toggleEdit}
              setUserImage={setUserImage}
              userImage={userImage}
            />
          )}
        </div>
      </div>
    </Spin>
  );
};

export default UserDetails;

const ViewUserDetails = ({ details, toggleEdit, setUserImage, userImage }) => {
  return (
    <>
      <Space size="large" direction="vertical">
        <div className="user-image">
          <Avatar
            size={170}
            icon={<img src={get(details?.image, "url")} alt="profile" />}
          />
        </div>
        <div>
          <div className="details-label">Your Name</div>
          <div>
            {get(details, "title")} {get(details, "name")}
          </div>
        </div>
        <div>
          <div className="details-label">Work Email Address</div>
          <div>{get(details, "email")}</div>
        </div>
        <div>
          <div className="details-label">Contact number</div>
          <div>
            {get(details, "contact_code")} {get(details, "contact_number")}
          </div>
        </div>
        <Button
          type="primary"
          onClick={toggleEdit}
          icon={
            <img
              src={edit_white}
              alt=""
              height="18px"
              style={{ marginRight: "4px" }}
            />
          }
        >
          Edit details
        </Button>
      </Space>
    </>
  );
};

const EditUserDetails = ({
  details,
  toggleEdit,
  toggleUserProfileDrawer,
  setLoading,
  setUserImage,
  userImage,
}) => {
  const dispatch = useDispatch();
  const roles = useSelector((state) => get(state, "auth.roles"));

  const hospital_uuid = useSelector((state) =>
    get(state, "auth.currentHospital.id")
  );

  // const [imageUser, setUserImage] = useState(
  //   {
  //   key: get(details, 'image').replace(
  //     // 'https://res.cloudinary.com/dd2sdadrl/image/upload/v1669621246/',
  //     `${process.env.CLOUDINARY_BUCKET_URL}`,
  //     ''
  //   ),
  //   url: get(details, 'image'),
  // }
  // );

  const onUploadLogoDone = (imageResponse) => {
    setUserImage(imageResponse);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const [contactCode, setContactCode] = useState(get(details, "contact_code"));
  const [contactNumber, setContactNumber] = useState(
    get(details, "contact_number")
  );

  const onFinish = (values) => {
    values = {
      ...values,
      contact_number: contactNumber,
      contact_code: contactCode,
    };
    setLoading(true);
    assign(values, { image: get(userImage, "key") });
    updateUser(values)
      .then((result) => {
        setLoading(false);
        toggleUserProfileDrawer();
        notification.success({
          message: DETAILS_UPDATED,
        });

        const updatedValues = {
          ...values,
          image: get(userImage, "key"),
          // 'https://res.cloudinary.com/dd2sdadrl/image/upload/v1669621246/' + get(values, 'image'),
        };
        if (roles.includes(1)) {
          dispatch({
            type: TYPES.UPDATE,
            data: updatedValues,
          });
        } else {
          dispatch(switchHospital({ hospital_uuid: hospital_uuid }))
            .then(() => {})
            .catch((error) => {});
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onChangeFormData = (value, key) => {
    if (key === "contact_number") {
      const phoneNumber = parsePhoneNumberFromString(String(value));
      if (phoneNumber && phoneNumber?.isValid()) {
        const formattedNumber = phoneNumber?.nationalNumber;
        const callingCode = phoneNumber?.countryCallingCode;
        setContactCode(callingCode);
        setContactNumber(formattedNumber);
      }
    }
  };

  return (
    <Form
      name="control-hooks"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      validateMessages={validateMessages}
      initialValues={{
        email: get(details, "email"),
        title: get(details, "title"),
        contact_number: "+" + contactCode + contactNumber,
        name: get(details, "name"),
      }}
    >
      <Form.Item>
        <SingleImageUpload
          className="user-image"
          onUploadDone={onUploadLogoDone}
          imageUrl={get(userImage, "url")}
          setUserImage={setUserImage}
        />
      </Form.Item>
      <Form.Item
        label="Your Name"
        name="name"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input className="custom-input" addonBefore={TitleSelector} />
      </Form.Item>
      <Form.Item
        label="Work Email"
        name="email"
        rules={[
          {
            required: true,
            type: "email",
          },
        ]}
      >
        <Input className="custom-input" disabled={true} />
      </Form.Item>
      <Form.Item
        label="Contact Number"
        name="contact_number"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <PhoneInput
          size="small"
          fontSize={{ fontSize: "14px !important" }}
          placeholder="Phone"
          onChange={(value) => onChangeFormData(value, "contact_number")}
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};
