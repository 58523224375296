import React, { useEffect } from "react";
import { Input, Form, Button, message } from "antd";
import moment from "moment";
import get from "lodash/get";
import ContactCodeSelector from "./ContactCodeSelector";
import { createEmergencyAppointment } from "../../redux/actions/doctorAction";

const OutsideForm = ({
  filteredUser,
  doctor_uuid,
  hospital_uuid,
  closeFormModal,
  currentSlot,
  slotType,
  openFormModal,
  filter,
  getAppointments,
  setAppointmentBooked
}) => {
  const [form] = Form.useForm(); // Form instance

  const session = { user: filteredUser };
  const onFinish = async(values) => {
    let data = {};
    data["doctor_uuid"] = doctor_uuid;
    data["hospital_uuid"] = hospital_uuid;
    data["date"] = moment().format("YYYY-MM-DD");
    data["name"] = values?.name;
    data["email"] = values?.email;
    data["contact_number"] = values?.contact_number;
    data["contact_code"] = values?.contact_code;
    data["user_uuid"] = filteredUser?.id;
    data["slot"] = currentSlot;
    data["type"] = slotType;
    if(!currentSlot){
      closeFormModal();
      message.error("Slot is not available now");
      getAppointments(
        get(filter, "page"),
        get(filter, "fromDate"),
        get(filter, "toDate"),
        get(filter, "days"),
        get(filter, "status"),
        get(filter, "query")
      );
      form.resetFields();
      return;
    }
    await createEmergencyAppointment(data)
      .then((result) => {
        form.resetFields();
        closeFormModal();
        message.success("Appointment Created Successfully");
        if(setAppointmentBooked){
          setAppointmentBooked(Math.random());
        }
        getAppointments(
          get(filter, "page"),
          get(filter, "fromDate"),
          get(filter, "toDate"),
          get(filter, "days"),
          get(filter, "status"),
          get(filter, "query")
        );
      })
      .catch((err) => {
        console.log("error", err);
        form.resetFields();
      });
  };
  useEffect(() => {
      form.resetFields();
  }, [openFormModal]);
  return (
    <>
      <Form
        form={form}
        name="appointment"
        layout="vertical"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        <div className="book-consultation-style">
          <div className="modal-title">Book Consultation</div>

          {/* <div className="select-mode checkbox-inner-style"> */}
          {/* <div className="text-info">
              For whom you wanna book consulation{' '}
              <span className="text-danger">*</span>
            </div> */}
          {/* </div> */}

          <div className="form-section">
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please Input Name",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter Name"
                // addonBefore={TitleSelector}
              />
            </Form.Item>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please Input email Address",
                },
                {
                  type: "email",
                  message: "Invalid Email Format",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter Email"
                // disabled={get(session, "user") ? true : false}
              />
            </Form.Item>

            <Form.Item
              label="Contact Number"
              name="contact_number"
              rules={[
                {
                  required: true,
                  message: "Please Input Contact number",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter Contact"
                addonBefore={ContactCodeSelector}
                // addonBefore={addonBefore={ContactCodeSelector}}
              />
            </Form.Item>
            <div className={`button-primary`}>
              <Form.Item>
                <Button type="primary" onClick={() => {}} htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};
export default OutsideForm;
