import get from "lodash/get";
import { Popover } from "antd";
import "./addressHelperStyles.scss";

const safeParseJSON = (value) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

const AddressHelper = ({ details }) => {
  const address1 = safeParseJSON(details?.address_1);
  const address2 = safeParseJSON(details?.address_2);

  const content = (
    <span>
          {get(address1, "selectedAddress", "")}, {get(address2, "selectedAddress", "")}, {" "}
      {get(details, "suburb")}, {get(details, "state")}, {get(details, "country")}, {get(details, "pin_code")}
    </span>
  );

  return (
    <div>
      <Popover content={content} title="Location">
        <span className="location">
          {get(address1, "selectedAddress", "")}, {get(address2, "selectedAddress", "")}, {" "}
          {get(details, "suburb")}, {get(details, "state")}, {" "}
          {get(details, "country")}, {get(details, "pin_code")}
        </span>
      </Popover>
    </div>
  );
};

export default AddressHelper;
