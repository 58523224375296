import { SearchOutlined } from "@ant-design/icons";
import {
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Table,
  Space,
  Drawer,
  Spin,
  Modal,
  Button,
  Form,
} from "antd";
import { Radio } from "antd";
import { appointmentsColumn } from "../../constants/table/appointmentsColumn";
import "./style.scss";
import map from "lodash/map";
import get from "lodash/get";
import { useEffect, useState } from "react";
import moment from "moment";
import MedicalHistory from "../../components/drawerContent/medicalHistory/MedicalHistory";
import requireAuth from "../../hocs/requireAuth";
import {
  fetchAppointments,
  updateAppointmentStatus,
  getDoctorsAppointmentSlots,
} from "../../redux/actions/doctorAction";
import { useSelector } from "react-redux";
import ConsultationForm from "../../components/drawerContent/consultationForm.js/ConsultationForm";
import AppointmentCancellationDetails from "../../components/appointmentCancellationDetails/AppointmentCancellationDetails";
import { filterDays, filterPatients } from "../../constants/options";
import RescheduleForm from "../../components/drawerContent/rescheduleForm/RescheduleForm";
import SimpleModal from "../../components/modals/simpleModal/SimpleModal";
import ReasonModal from "../../components/modals/reasonModal/ReasonModal";
import DoneModal from "../../components/modals/doneModal/DoneModal";
import { YEAR_MONTH_DAY } from "../../constants/constantsDateFormats";
import EmergencyFormScreen from "../EmergencyFormScreen/EmergencyFormScreen";
import EmergencyFormFamily from "../EmergencyFormScreen/EmergencyFormFamily";
import RaiseClaimDetails from "../../components/raiseClaim/RaiseClaimDetails";
import ViewClaimDetails from "../../components/raiseClaim/ViewClaimDetails";
import UpdateStatusForm from "../../components/drawerContent/updateAppointmentStatus/UpdateStatusForm";
import ShowDelayScreen from "../ShowDoctorsDelayScreen/ShowDelayScreen";
import PatientAnswersForm from "../../components/drawerContent/patientAnswersForm/PatientAnswersForm";
const { RangePicker } = DatePicker;
const { Search } = Input;

const { Option } = Select;

const AppointmentsScreen = () => {
  const [form] = Form.useForm();
  const roles = useSelector((state) => get(state, "auth.roles"));
  const [appointmentBooked, setAppointmentBooked] = useState(false);
  const [drawer, setDrawer] = useState({
    visible: false,
    edit: false,
    data: null,
  });

  const [appointmentsResult, setAppointmentsResult] = useState({
    appointments: [],
    pagination: null,
  });
  const [loading, setLoading] = useState(false);
  const [appointmentId, setAppointmentId] = useState();
  const [message, setMessage] = useState();

  const userId = useSelector((state) => get(state, "auth.id"));
  const [filter, setFilter] = useState({
    page: 1,
    fromDate: moment().format(YEAR_MONTH_DAY),
    toDate: moment().format(YEAR_MONTH_DAY),
    days: 1,
    status: null,
    query: "",
  });

  const [consultationForm, setConsultationForm] = useState({
    visible: false,
    edit: false,
    data: null,
  });
  const [patientAnswersForm, setPatientAnswersForm] = useState({
    visible: false,
    edit: false,
    data: null,
  });
  const [updateStatusForm, setUpdateStatusForm] = useState({
    visible: false,
    edit: false,
    data: null,
  });

  const [filteredUser, setFilteredUser] = useState(null);
  const [consultationType, setConsultationType] = useState(null);

  const toggleConsultationForm = (data = null) => {
    setConsultationForm({
      visible: !get(consultationForm, "visible"),
      edit: !get(consultationForm, "visible"),
      data,
    });
  };

  const togglePatientAnswersForm = (data = null) => {
    setPatientAnswersForm({
      visible: !get(patientAnswersForm, "visible"),
      edit: !get(patientAnswersForm, "visible"),
      data,
    });
  };

  const toggleUpdateStatusForm = (data = null) => {
    setUpdateStatusForm({
      visible: !get(updateStatusForm, "visible"),
      edit: !get(updateStatusForm, "visible"),
      data,
    });
  };

  const [rescheduleDrawer, setRescheduleDrawer] = useState({
    visible: false,
    edit: false,
    data: null,
  });

  const toggleReschedule = (data = null) => {
    setRescheduleDrawer({
      visible: !get(rescheduleDrawer, "visible"),
      edit: !get(rescheduleDrawer, "visible"),
      data,
    });
  };

  const [cancellationNotesDrawer, setCancellationNotesDrawer] = useState({
    visible: false,
    data: null,
  });

  const toggleCancelAppointmentNotes = (data = null) => {
    setCancellationNotesDrawer({
      visible: !get(cancellationNotesDrawer, "visible"),
      data,
    });
  };

  const getAppointments = (
    page,
    fromDate,
    toDate,
    days,
    status = null,
    query = ""
  ) => {
    setLoading(true);
    fetchAppointments(fromDate, toDate, status, page, query)
      .then((result) => {
        setLoading(false);
        setAppointmentsResult({
          appointments: get(result, "data"),
          pagination: get(result, "meta.pagination"),
        });
        setFilter({ page, fromDate, toDate, days, status, query });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const cancelAppointment = (reason) => {
    setLoading(true);
    const payload = { status: 4, cancel_reason: reason };

    updateAppointmentStatus(appointmentId, payload)
      .then((result) => {
        setLoading(false);
        toggleDone("Appointment Cancelled!");
        getAppointments(
          get(filter, "page"),
          get(filter, "fromDate"),
          get(filter, "toDate"),
          get(filter, "days"),
          get(filter, "status"),
          get(filter, "query")
        );
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAppointments(
      get(filter, "page"),
      get(filter, "fromDate"),
      get(filter, "toDate"),
      get(filter, "days"),
      get(filter, "status"),
      get(filter, "query")
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleMedicalHistory = (data = null) => {
    setDrawer({
      visible: !get(drawer, "visible"),
      edit: !get(drawer, "visible"),
      data,
    });
  };

  const handleDays = (days) => {
    const toDate = moment().format(YEAR_MONTH_DAY);
    const fromDate = moment(moment())
      .subtract(days, "d")
      .format(YEAR_MONTH_DAY);

    getAppointments(
      get(filter, "page"),
      fromDate,
      toDate,
      days,
      get(filter, "status"),
      get(filter, "query")
    );
  };

  const handleRange = (dates) => {
    if (dates) {
      const toDate = moment(dates[1]).format(YEAR_MONTH_DAY);
      const fromDate = moment(dates[0]).format(YEAR_MONTH_DAY);
      getAppointments(
        get(filter, "page"),
        fromDate,
        toDate,
        "null",
        get(filter, "status"),
        get(filter, "query")
      );
    }
  };

  const onSearch = (value) => {
    getAppointments(
      get(filter, "page"),
      get(filter, "fromDate"),
      get(filter, "toDate"),
      get(filter, "days"),
      get(filter, "status"),
      value
    );
  };

  const hospital_uuid = useSelector((state) => {
    return get(state, "auth.currentHospital.id");
  });
  const hospital_slug = useSelector((state) =>
    get(state, "auth.currentHospital.slug")
  );

  const current_assistant_uuid = useSelector((state) => {
    const roles = get(state, "auth.user.roles", []);
    const isRoleValue5 = roles.some((role) => role?.value === 5); // Check if any role has value === 5
    return isRoleValue5 ? get(state, "auth.user.id") : null; // Return user ID if role value is 5
  });

  const currentDoctorId = useSelector((state) =>
    get(state, "auth.currentDoctor.uuid")
  );
  const currentUserId = useSelector((state) => get(state, "auth.user.id"));

  const current_doctor_uuid = current_assistant_uuid
    ? currentDoctorId
    : currentUserId;

  const [cancelModal, setCancelModal] = useState(false);
  const toggleCancel = (data = null) => {
    setCancelModal(!cancelModal);
    setAppointmentId(get(data, "id"));
  };

  const [reasonModal, setReasonModal] = useState(false);
  const toggleReason = () => {
    setCancelModal(false);
    setReasonModal(!reasonModal);
  };

  const [doneModal, setDoneModal] = useState(false);
  const toggleDone = (message) => {
    setCancelModal(false);
    setReasonModal(false);
    setMessage(message);
    setDoneModal(!doneModal);
  };
  const [showEmergency, setShowEmergency] = useState(false);
  const [showDelay, setShowDelay] = useState(false);
  const showModal = () => {
    form.resetFields();
    setShowEmergency(true);
  };
  const closeModalForChilds = () => {
    form.resetFields();
    setShowEmergency(false);
  };
  const closeModal = () => {
    form.resetFields();
    setFilteredUser(null);
    setConsultationType(null);
    setShowEmergency(false);
  };
  const showDelayModal = () => {
    setShowDelay(true);
  };
  const closeDelayModal = () => {
    setShowDelay(false);
  };
  const [familyModal, setFamilyModal] = useState(false);
  const [patientData, setPatientData] = useState(null);
  const [patientCurrentSlot, setPatientCurrentSlot] = useState(null);
  const [patientSlotType, setPatientSlotType] = useState(null);
  const onClickFamily = (record) => {
    setPatientData(record?.patient?.user);
    setPatientCurrentSlot(record?.slot);
    setPatientSlotType(record?.type);
    setFamilyModal(true);
  };
  const onCloseFamily = () => {
    setFamilyModal(false);
  };
  const [currentSlot, setCurrentSlot] = useState();
  const [slotType, setSlotType] = useState();
  const [claimModal, setClaimModal] = useState(false);
  const [viewClaimModal, setViewClaimModal] = useState(false);
  const [claimAppointment, setClaimAppointment] = useState();
  const onClickRaiseClaim = (record) => {
    setClaimAppointment(record);
    setClaimModal(true);
  };
  const onClickUpdateClaim = (record) => {
    const claimItems = JSON.parse(record?.claim?.items);
    let arr = [];
    for (let i = 0; i < claimItems?.length; i++) {
      const item = {
        ...claimItems[i],
        date: moment(claimItems[i]?.date, "YYYY-MM-DD"),
      };
      arr.push(item);
    }
    let claim = { ...record?.claim, items: arr };
    record = { ...record, claim: claim };
    setClaimAppointment(record);
    setClaimModal(true);
  };
  const onClickViewClaim = (record) => {
    let claim = { ...record?.claim, items: JSON.parse(record?.claim?.items) };
    record = { ...record, claim: claim };
    setClaimAppointment(record);
    setViewClaimModal(true);
  };
  const onCloseClaim = () => {
    setClaimModal(false);
  };
  const onCloseViewClaim = () => {
    setViewClaimModal(false);
  };

  useEffect(() => {
    getDoctorsAppointmentSlots(
      current_doctor_uuid,
      hospital_uuid,
      moment().format("YYYY-MM-DD")
    )
      .then((result) => {
        getTimeSlots(result);

      })
      .catch((err) => {
        console.log("error", err);
      });
  }, [appointmentBooked]);
  
  const getTimeSlots = (result) => {
    // Filter out booked slots and sort by start time
    const availableSlots = result?.filter(timeSlot => !timeSlot?.booked)
        ?.sort((a, b) => {
            const timeA = new Date(`1970/01/01 ${a.slot_start}`);
            const timeB = new Date(`1970/01/01 ${b.slot_start}`);
            return timeA - timeB;
        });
    console.log("availableSlots", availableSlots);
    const currentDate = new Date();
    let nextSlot = null;

    // First try to find a slot where current time falls within the window
    nextSlot = availableSlots?.find(timeSlot => {
        const slotStartDate = new Date(currentDate.getTime());
        const slotEndDate = new Date(currentDate.getTime());
        
        slotStartDate.setHours(timeSlot.slot_start.split(":")[0]);
        slotStartDate.setMinutes(timeSlot.slot_start.split(":")[1]);
        
        slotEndDate.setHours(timeSlot.slot_end.split(":")[0]);
        slotEndDate.setMinutes(timeSlot.slot_end.split(":")[1]);
        
        return currentDate >= slotStartDate && currentDate <= slotEndDate;
    });
    console.log("nextSlot", nextSlot);

    // If no current slot found, find next available slot
    if (!nextSlot) {
        nextSlot = availableSlots?.find(timeSlot => {
            const slotStartDate = new Date(currentDate.getTime());
            slotStartDate.setHours(timeSlot.slot_start.split(":")[0]);
            slotStartDate.setMinutes(timeSlot.slot_start.split(":")[1]);
            return slotStartDate > currentDate;
        });
    }
    console.log("nextSlot", nextSlot);


    // If still no slot found, take the first available slot of the day
    if (!nextSlot && availableSlots?.length > 0) {
        nextSlot = availableSlots[0];
    }

    console.log("nextSlot", nextSlot);
    // If we found a slot, set it
    if (nextSlot) {
        setCurrentSlot(nextSlot.id);
        setSlotType(nextSlot.type);
    }
  };
  console.log("currentSlot", currentSlot);
  console.log("slotType", slotType);
  return (
    <Spin spinning={loading}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="screen-title">Appointments</div>
        <div>
          {roles?.[0] === 3 && (
            <Button
              type="primary"
              onClick={showDelayModal}
              style={{ cursor: "pointer", marginRight: "12px" }}
            >
              Add Availability Delay
            </Button>
          )}
          {roles?.[0] === 2 || roles?.[0] === 5 ? null : (
            <Button
              type="primary"
              onClick={showModal}
              style={{ cursor: "pointer" }}
            >
              AdHoc
            </Button>
          )}
        </div>
      </div>
      <Row className="search-appointment" justify="space-between">
        <Col xs={24} md={6}>
          {/* <Input
            placeholder="Doctor, specialty, hospital or treatment"
            allowClear
            size="large"
            onSearch={onSearch}
            prefix={<SearchOutlined style={{ fontSize: '12px' }} />}
          /> */}
          <Search
            placeholder="Doctor or Patient"
            // specialty, hospital or treatment"
            className="search-input"
            allowClear
            enterButton={false}
            suffix={false}
            size="large"
            onSearch={onSearch}
            prefix={<SearchOutlined style={{ fontSize: "12px" }} />}
          />
        </Col>
        <Col xs={24} md={18}>
          <Row align="middle" justify="end" gutter={[20, 10]}>
            <Col>
              <Radio.Group
                options={filterDays}
                value={get(filter, "days")}
                optionType="button"
                buttonStyle="solid"
                onChange={(event) => handleDays(get(event, "target.value"))}
              />
            </Col>
            <Col>
              <RangePicker
                onChange={(dateStrings) => handleRange(dateStrings)}
              />
            </Col>
            <Col>
              <Select
                value={get(filter, "status")}
                onChange={(value) =>
                  getAppointments(
                    get(filter, "page"),
                    get(filter, "fromDate"),
                    get(filter, "toDate"),
                    get(filter, "days"),
                    value,
                    get(filter, "query")
                  )
                }
              >
                {map(filterPatients, (filter, i) => (
                  <Option key={i} value={get(filter, "value")}>
                    {get(filter, "label")}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal open={showEmergency} onCancel={closeModal} footer={null}>
        <EmergencyFormScreen
          selectedFilteredUser={filteredUser}
          setSelectedFilteredUser={setFilteredUser}
          consultationType={consultationType}
          setConsultationType={setConsultationType}
          doctor_uuid={current_doctor_uuid}
          hospital_uuid={hospital_uuid}
          currentSlot={currentSlot}
          slotType={slotType}
          filteredUser={patientData}
          closeModal={closeModalForChilds}
          getAppointments={getAppointments}
          filter={filter}
          setAppointmentBooked={setAppointmentBooked}
        />
      </Modal>
      <Modal open={showDelay} onCancel={closeDelayModal} footer={null}>
        <ShowDelayScreen
          doctor_uuid={current_doctor_uuid}
          hospital_uuid={hospital_uuid}
          currentSlot={currentSlot}
          slotType={slotType}
          filteredUser={patientData}
          closeDelayModal={closeDelayModal}
        />
      </Modal>
      <Table
        columns={appointmentsColumn({
          userId,
          roles,
          toggleCancel,
          toggleReschedule,
          toggleMedicalHistory,
          toggleConsultationForm,
          toggleCancelAppointmentNotes,
          onClickFamily,
          toggleUpdateStatusForm,
          onClickRaiseClaim,
          onClickUpdateClaim,
          onClickViewClaim,
          togglePatientAnswersForm,
        })}
        dataSource={get(appointmentsResult, "appointments")}
        scroll={{ x: true }}
        pagination={{
          pageSize: get(appointmentsResult, "pagination.per_page", 1),
          current: get(appointmentsResult, "pagination.current_page", 1),
          total: get(appointmentsResult, "pagination.total", 1),
          onChange: (val) => {
            getAppointments(
              val,
              get(filter, "fromDate"),
              get(filter, "toDate"),
              get(filter, "days"),
              get(filter, "status"),
              get(filter, "query")
            );
          },

          position: ["bottomLeft"],
        }}
      />
      <Drawer
        className="hospital-details-drawer main-drawer"
        destroyOnClose
        //width="60%"
        closable={false}
        onClose={() => toggleMedicalHistory(null)}
        visible={get(drawer, "visible")}
      >
        <MedicalHistory
          toggleMedicalHistory={toggleMedicalHistory}
          patient_uuid={get(drawer, "data.patient.id")}
          patient_details={get(drawer, "data.patient")}
          appointment_id={get(drawer, "data.appointment_id")}
          setLoading={setLoading}
        />
      </Drawer>
      <Drawer
        className="drawer-50 main-drawer"
        destroyOnClose
        closable={false}
        onClose={() => toggleConsultationForm(null)}
        visible={get(consultationForm, "visible")}
      >
        <ConsultationForm
          toggleConsultationForm={toggleConsultationForm}
          appointment_uuid={get(consultationForm, "data.id")}
          appointment_details={get(consultationForm, "data")}
          getAppointments={getAppointments}
          filter={filter}
        />
      </Drawer>
      <Drawer
        className="drawer-50 main-drawer"
        destroyOnClose
        closable={false}
        onClose={() => togglePatientAnswersForm(null)}
        open={get(patientAnswersForm, "visible")}
      >
        <PatientAnswersForm
          togglePatientAnswersForm={togglePatientAnswersForm}
          appointment_details={get(patientAnswersForm, "data")}
        />
      </Drawer>
      <Drawer
        className="main-drawer"
        destroyOnClose
        width="50%"
        closable={false}
        onClose={() => toggleUpdateStatusForm(null)}
        open={get(updateStatusForm, "visible")}
      >
        <UpdateStatusForm
          toggleUpdateStatusForm={toggleUpdateStatusForm}
          appointment_uuid={get(updateStatusForm, "data.id")}
          appointment_details={get(updateStatusForm, "data")}
          getAppointments={getAppointments}
          filter={filter}
        />
      </Drawer>
      <Drawer
        className="main-drawer"
        destroyOnClose
        width="40%"
        closable={false}
        onClose={() => toggleReschedule(null)}
        visible={get(rescheduleDrawer, "visible")}
      >
        <RescheduleForm
          toggleReschedule={toggleReschedule}
          doctor_uuid={get(rescheduleDrawer, "data.doctor.id")}
          hospital_uuid={hospital_uuid}
          appointment_uuid={get(rescheduleDrawer, "data.id")}
          getAppointments={getAppointments}
          filter={filter}
        />
      </Drawer>
      <SimpleModal
        okText="Yes"
        closable={false}
        centered={true}
        visible={cancelModal}
        onCancel={() => toggleCancel()}
        onOk={() => toggleReason()}
        text="Do you want to cancel appointment?"
      />
      <ReasonModal
        visible={reasonModal}
        onDone={cancelAppointment}
        onCancel={() => toggleReason()}
        title="Reason to Cancel Appointment?"
      />
      <Drawer
        className="main-drawer"
        destroyOnClose
        width={350}
        closable={false}
        onClose={() => toggleCancelAppointmentNotes(null)}
        visible={get(cancellationNotesDrawer, "visible")}
      >
        <AppointmentCancellationDetails
          toggleCancelAppointmentNotes={toggleCancelAppointmentNotes}
          details={get(cancellationNotesDrawer, "data")}
        />
      </Drawer>
      <DoneModal
        message={message}
        visible={doneModal}
        toggleDone={toggleDone}
      />
      {familyModal && (
        <Modal open={familyModal} onCancel={onCloseFamily} footer={null}>
          <EmergencyFormFamily
            doctor_uuid={current_doctor_uuid}
            hospital_uuid={hospital_uuid}
            filteredUser={patientData}
            currentSlot={patientCurrentSlot}
            slotType={patientSlotType}
            closeFormModal={onCloseFamily}
            filter={filter}
            getAppointments={getAppointments}
          />
        </Modal>
      )}
      <Drawer
        className="main-drawer"
        width={350}
        onClose={onCloseClaim}
        visible={claimModal}
      >
        <RaiseClaimDetails
          appointment_details={claimAppointment}
          getAppointments={getAppointments}
          filter={filter}
          onCloseClaim={onCloseClaim}
        />
      </Drawer>
      <Drawer
        className="main-drawer"
        width={350}
        onClose={onCloseViewClaim}
        visible={viewClaimModal}
      >
        <ViewClaimDetails appointment_details={claimAppointment} />
      </Drawer>
    </Spin>
  );
};

export default requireAuth(AppointmentsScreen, [2, 3, 4, 5]);
