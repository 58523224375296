import {
  InputNumber,
  Row,
  Col,
  Select,
  Input,
  Checkbox,
  Button,
  Form,
  Space,
  notification,
} from "antd";
import map from "lodash/map";
import get from "lodash/get";
import assign from "lodash/assign";

// Styles
import "./styles.scss";
import { useEffect, useState } from "react";
import { validateMessages } from "../../../constants/validation";
import { ContactCodeSelector } from "../../contactCodeSelector/ContactCodeSelector";
import { specList } from "../../../redux/actions/doctorAction";
import { updateHospitalDetails } from "../../../redux/actions/hospitalAction";

import { getTimeHelper } from "../../../utilities/selectTimeHelper/getTimeHelper";
import SelectWorkingHours from "../../selectWorkingHours";
import { putTimeHelper } from "../../../utilities/selectTimeHelper/putTimeHelper";
import { DETAILS_UPDATED } from "../../../constants/messages";
import { switchHospital } from "../../../redux/actions/authAction";
import { useDispatch } from "react-redux";
import { getCityList } from "../../../redux/actions/superAdminAction";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  geocodeByLatLng,
  getLatLng,
} from "react-google-places-autocomplete";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import "react-phone-number-input/style.css";

const { Option } = Select;
const { TextArea } = Input;

const HospitalDetailEdit = ({
  details,
  onSave,
  fetchDetails,
  loading,
  setLoading,
  hospital_uuid,
}) => {
  const dispatch = useDispatch();
  const [specialisations, setSpecialisation] = useState([]);
  const [cities, setCities] = useState([]);

  const [sponsorship, setSponsorship] = useState(
    get(details, "sponsership_required")
  );

  const [openHours, setOpenHours] = useState([]);
  const onChange = (newDays, index) => {
    const newOpenHours = [...openHours];
    newOpenHours[index] = newDays;
    setOpenHours(newOpenHours);
  };

  const getCities = () => {
    setLoading(true);
    getCityList()
      .then((result) => {
        setLoading(false);
        console.log("City list is>>>", result);
        setCities(result);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleSponsorship = () => {
    setSponsorship(!sponsorship);
  };

  useEffect(() => {
    specList()
      .then((result) => {
        setSpecialisation(result);
      })
      .catch((err) => {
        console.log("error", err);
      });
    setOpenHours(getTimeHelper(get(details, "working_hours")));
    getCities();
  }, []);

  const [form] = Form.useForm();
  const [selectedLocation, setSelectedLocation] = useState(
    details && details?.address_1
      ? JSON.parse(details?.address_1)?.selectedAddress
      : ""
  );
  const [selectedLocation_2, setSelectedLocation_2] = useState(
    details && details?.address_2
      ? JSON.parse(details?.address_2)?.selectedAddress
      : ""
  );

  const [latLng, setLatLng] = useState({
    lat: details?.address_1 ? details?.address_1?.latLng?.lat : "",
    lng: details?.address_1 ? details?.address_1?.latLng?.lng : "",
  });
  const [latLng_2, setLatLng_2] = useState({
    lat_2: details?.address_2 ? details?.address_2?.latLng_2?.lat_2 : "",
    lng_2: details?.address_2 ? details?.address_2?.latLng_2?.lng_2 : "",
  });
  const [selectedAddress1, setSelectedAddress1] = useState(
    details?.address_1 ? details?.address_1 : ""
  );
  const [selectedAddress2, setSelectedAddress2] = useState(
    details?.address_2 ? details?.address_2 : ""
  );
  const [placeId, setPlaceId] = useState(details?.address_1?.placeId);
  const [placeId_2, setPlaceId_2] = useState(details?.address_2?.placeId_2);
  const onSuccessAddress_1 = (value, latLng) => {
    setSelectedAddress1(
      JSON.stringify({
        latLng: { lat: latLng?.lat, lng: latLng?.lng },
        placeId: value?.place_id,
        selectedAddress: value?.formatted_address,
      })
    );
  };

  const onSuccessAddress_2 = (value, latLng) => {
    setSelectedAddress2(
      JSON.stringify({
        latLng: { lat: latLng?.lat, lng: latLng?.lng },
        placeId: value?.place_id,
        selectedAddress: value?.formatted_address,
      })
    );
  };
  const [contactCode, setContactCode] = useState(get(details, "contact_code"));
  const [contactNumber, setContactNumber] = useState(
    get(details, "contact_number")
  );

  const onChangeFormData = (value, key) => {
    console.log("value", value, key);
    if (key === "contact_number") {
      const phoneNumber = parsePhoneNumberFromString(String(value));
      if (phoneNumber && phoneNumber?.isValid()) {
        const formattedNumber = phoneNumber?.nationalNumber;
        const callingCode = phoneNumber?.countryCallingCode;
        setContactCode(callingCode);
        setContactNumber(formattedNumber);
      }
    }
  };

  const onFinish = (values) => {
    values = {
      ...values,
      contact_number: contactNumber,
      contact_code: contactCode,
      address_1: selectedAddress1,
      address_2: selectedAddress2,
    };
    setLoading(true);
    assign(values, { step: 1 });
    assign(values, { working_hours: putTimeHelper(openHours) });
    updateHospitalDetails(hospital_uuid, values)
      .then((result) => {
        setLoading(false);
        const hospital_id = { hospital_uuid: hospital_uuid };
        //     dispatch(switchHospital(hospital_id))
        //       .then(() => {})
        //       .catch((error) => {});

        fetchDetails();
        onSave();
        notification.success({
          message: DETAILS_UPDATED,
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const getGeoMetry = (details, geometry) => {
    const geo = geometry[0].geometry;
    var addressData = {};
    const address_components = get(geometry[0], "address_components");
    for (var i = 0; i < address_components.length; i++) {
      if (get(address_components[i], "types").includes("country")) {
        assign(addressData, {
          address_country: get(address_components[i], "long_name"),
        });
      }
      if (get(address_components[i], "types").includes("postal_code")) {
        assign(addressData, {
          pin_code: get(address_components[i], "long_name"),
        });
      }
      if (
        get(address_components[i], "types").includes(
          "administrative_area_level_1"
        )
      ) {
        assign(addressData, {
          state: get(address_components[i], "long_name"),
        });
      }
      if (get(address_components[i], "types").includes("sublocality_level_1")) {
        assign(addressData, {
          suburb: get(address_components[i], "long_name"),
        });
      }
    }
  };
  return (
    <div className="hospital-component-style">
      <div className="form">
        <Form
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateMessages={validateMessages}
          initialValues={{
            name: get(details, "name"),
            specialisations: map(get(details, "specialisations"), (s, i) =>
              get(s, "id")
            ),
            description: get(details, "description"),
            contact_number: "+" + contactCode + contactNumber,
            fax_number: get(details, "fax_number"),
            address_1: get(
              details?.address_1 && JSON.parse(details?.address_1),
              "selectedAddress"
            ),
            address_2: get(
              details?.address_2 && JSON.parse(details?.address_2),
              "selectedAddress"
            ),
            suburb: get(details, "suburb"),
            website: get(details, "website"),
            doctor_count: get(details, "doctor_count"),
            sponsership_required: get(details, "sponsership_required"),
            contact_code: get(details, "contact_code"),
            city_uuid: get(details, "city.id"),
            // billing: get(details, ''),
          }}
        >
          <Row gutter={[40, 20]}>
            <Col span={8}>
              <Form.Item
                label="Hospital Name"
                name="name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input className="custom-input" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Specialisation"
                name="specialisations"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  size="large"
                  mode="multiple"
                  style={{ fontSize: "14px" }}
                >
                  {map(specialisations, (s, i) => (
                    <Option key={i} value={get(s, "id")}>
                      {get(s, "name")}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="About"
                name="description"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Contact Number"
                name="contact_number"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <PhoneInput
                  size="small"
                  fontSize={{ fontSize: "14px !important" }}
                  placeholder="Phone"
                  onChange={(value) =>
                    onChangeFormData(value, "contact_number")
                  }
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Fax Number"
                name="fax_number"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input className="custom-input" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="City"
                name="city_uuid"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select size="large">
                  {map(cities, (city, index) => (
                    <Option key={index} value={get(city, "id")}>
                      {get(city, "name")}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Address 1"
                name="address_1"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyCGT1QmwgNq90STSmzbKU3s9UQm_CofGfc"
                  selectProps={{
                    defaultInputValue: selectedLocation,
                    onChange: async (va) => {
                      geocodeByPlaceId(get(va, "value.place_id")).then(
                        (results) => {
                          getGeoMetry(va, results);
                          let filterData = results?.filter(
                            (result) => result.place_id == va.value.place_id
                          )[0];
                          setPlaceId(va?.value?.place_id);
                          getLatLng(filterData).then((latLng) => {
                            setLatLng({
                              lat: latLng.lat,
                              lng: latLng.lng,
                            });
                            onSuccessAddress_1(filterData, latLng);
                          });
                        }
                      );
                    },
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Address 2"
                name="address_2"
                // rules={[
                //   {
                //     required: true,
                //   },
                // ]}
              >
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyCGT1QmwgNq90STSmzbKU3s9UQm_CofGfc"
                  selectProps={{
                    defaultInputValue: selectedLocation_2,
                    onChange: async (va) => {
                      geocodeByPlaceId(get(va, "value.place_id")).then(
                        (results) => {
                          getGeoMetry(va, results);
                          let filterData = results?.filter(
                            (result) => result.place_id == va.value.place_id
                          )[0];
                          setPlaceId_2(va?.value?.place_id);
                          getLatLng(filterData).then((latLng) => {
                            setLatLng_2({
                              lat_2: latLng.lat,
                              lng_2: latLng.lng,
                            });
                            onSuccessAddress_2(filterData, latLng);
                          });
                        }
                      );
                    },
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Suburb"
                name="suburb"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input className="custom-input" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Website Url"
                name="website"
                rules={[{ required: true }]}
              >
                <Input className="custom-input" addonBefore="https://" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Number of doctors"
                name="doctor_count"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber min={0} className="custom-input" disabled={true} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Open Hours">
                <Space direction="vertical" size="middle">
                  <div className="form-label">Your Availability</div>
                  <div>
                    <div className="small-label">
                      <Row>
                        <Col span={12} push={12}>
                          Work Hours
                        </Col>
                        {/* <Col span={12} pull={12}>
                          Work days
                        </Col> */}
                      </Row>
                    </div>

                    <SelectWorkingHours
                      openHours={openHours}
                      setOpenHours={setOpenHours}
                      onChange={onChange}
                    />
                  </div>
                </Space>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Sponsorship required? "
                name="sponsership_required"
                valuePropName="checked"
              >
                <Checkbox onChange={handleSponsorship} checked={sponsorship}>
                  Yes
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default HospitalDetailEdit;
